exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-goats-birds-eye-farm-elena-ferrante-js": () => import("./../../../src/pages/goats/birds-eye-farm-elena-ferrante.js" /* webpackChunkName: "component---src-pages-goats-birds-eye-farm-elena-ferrante-js" */),
  "component---src-pages-goats-birds-eye-farm-ina-may-js": () => import("./../../../src/pages/goats/birds-eye-farm-ina-may.js" /* webpackChunkName: "component---src-pages-goats-birds-eye-farm-ina-may-js" */),
  "component---src-pages-goats-birds-eye-farm-lizabeth-darcy-js": () => import("./../../../src/pages/goats/birds-eye-farm-lizabeth-darcy.js" /* webpackChunkName: "component---src-pages-goats-birds-eye-farm-lizabeth-darcy-js" */),
  "component---src-pages-goats-birds-eye-farm-zora-neale-js": () => import("./../../../src/pages/goats/birds-eye-farm-zora-neale.js" /* webpackChunkName: "component---src-pages-goats-birds-eye-farm-zora-neale-js" */),
  "component---src-pages-goats-diji-farm-cacao-nib-js": () => import("./../../../src/pages/goats/diji-farm-cacao-nib.js" /* webpackChunkName: "component---src-pages-goats-diji-farm-cacao-nib-js" */),
  "component---src-pages-goats-harley-hillside-dime-piece-js": () => import("./../../../src/pages/goats/harley-hillside-dime-piece.js" /* webpackChunkName: "component---src-pages-goats-harley-hillside-dime-piece-js" */),
  "component---src-pages-goats-harley-hillside-ginger-zinger-js": () => import("./../../../src/pages/goats/harley-hillside-ginger-zinger.js" /* webpackChunkName: "component---src-pages-goats-harley-hillside-ginger-zinger-js" */),
  "component---src-pages-goats-index-js": () => import("./../../../src/pages/goats/index.js" /* webpackChunkName: "component---src-pages-goats-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

